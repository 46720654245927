import {
  gmailAuthorizationUrl,
  bindGMailAccount,
  pageByAuthUser,
  myAuthConfig,
  getMailAccountList,
  authEmail,
  myAuthEmail,
  changeUserAuthConfig,
  otherCancelAuthEmail,
  delAuthEmail,
  cancelAuthEmail,
  findCentralConfig,
  centralConfig,
  tenantEmails,
  centerEnable,
  getMailAccountListByUserId,
  getEmailNumber,
  getEmailAuthNumber,
  processed,
  centralUserList,
  centralEmailList,
  mailSettingDetail, //邮件配置详情
} from '@/core/services/api/mail/setting'

import {
  mailCheckerList,
  getMemberMailList,
} from '@/core/services/api/mail/inspection'

import { getMailDetailById } from '@/core/services/api/mail/mail'
class mailSetting {
  static getInstance() {
    return this._instance
  }

  /**
   * 获取Gmail的授权URL。
   *
   * @return {Promise<string>} Gmail的授权URL。
   */
  async gmailAuthorizationUrlApi(exportHead) {
    return await gmailAuthorizationUrl({
      exportHead,
    })
  }

  /**
   * 绑定 GMail 账户 API。
   *
   * @param {Object} options - 绑定 GMail 账户的选项。
   * @param {string} options.businessId - 商业的 ID。
   * @param {string} options.businessName - 商业的名称。
   * @param {string} options.businessNameEn - 商业的英文名称。
   * @param {string} options.code - 绑定 GMail 账户的代码。
   * @param {boolean} options.exportHead - 是否导出头部。
   * @param {string} options.tenantId - 租户的 ID。
   * @return {Promise} 一个返回绑定 GMail 账户结果的 Promise。
   */
  async bindGMailAccountApi({
    businessId,
    businessName,
    businessNameEn,
    code,
    exportHead,
    tenantId,
  }) {
    return bindGMailAccount({
      businessId,
      businessName,
      businessNameEn,
      code,
      exportHead,
      tenantId,
    })
  }

  async pageByAuthUserApi({ pageSize, pageNo }) {
    return pageByAuthUser({
      pageSize,
      pageNo,
    })
  }

  /**
   * 执行 `myAuthConfig` 函数并异步返回结果。
   * @return {Promise} 一个 Promise 对象，解析为 `myAuthConfig` 函数的结果。
   */
  async myAuthConfigApi() {
    return myAuthConfig()
  }

  /**
   * 获取邮件账户列表的API函数。
   *
   * @return {Object} 邮件账户列表。
   */
  getMailAccountListApi() {
    return getMailAccountList()
  }

  /**
   * 新增授权
   *
   * @param {type} authUserId - 身份验证用户的ID。
   * @param {type} authUserNameCn - 身份验证用户的中文名。
   * @param {type} authUserNameEn - 身份验证用户的英文名。
   * @param {type} emailAccount - 要进行身份验证的电子邮件帐户。
   * @return {type} 电子邮件身份验证的结果。
   */
  authEmailApi({ authUserId, authUserNameCn, authUserNameEn, emailAccount }) {
    return authEmail({
      authUserId,
      authUserNameCn,
      authUserNameEn,
      emailAccount,
    })
  }

  /**
   * 查询我的授权记录
   *
   * @return {Object} 邮件账户列表。
   */
  myAuthEmailApi() {
    return myAuthEmail()
  }

  /**
   * 调用changeUserAuthConfig API的函数，使用给定的参数。
   *
   * @param {Object} param - 包含API调用参数的对象。
   * @param {boolean} param.deleted - 删除状态。
   * @param {boolean} param.emailReminded - 邮件提醒状态。
   * @param {string} param.forwardStatus - 转发状态。
   * @param {string} param.id - ID。
   * @param {boolean} param.readStatus - 阅读状态。
   * @param {boolean} param.replyStatus - 回复状态。
   * @param {string} param.tenantId - 租户ID。
   * @param {string} param.updateTime - 更新时间。
   * @param {string} param.updatorId - 更新人ID。
   * @param {string} param.userId - 用户ID。
   * @return {Promise} 返回一个Promise对象，该对象解析为API调用的结果。
   */
  changeUserAuthConfigApi({
    deleted,
    emailReminded,
    forwardStatus,
    id,
    readStatus,
    replyStatus,
    tenantId,
    updateTime,
    updatorId,
    userId,
    heartEnabled,
  }) {
    return changeUserAuthConfig({
      deleted,
      emailReminded,
      forwardStatus,
      id,
      readStatus,
      replyStatus,
      tenantId,
      updateTime,
      updatorId,
      userId,
      heartEnabled,
    })
  }

  otherCancelAuthEmailApi({ id, status, updatorId }) {
    return otherCancelAuthEmail({
      id,
      status,
      updatorId,
    })
  }

  delAuthEmailApi({ id }) {
    return delAuthEmail({
      id,
    })
  }

  cancelAuthEmailApi({ id }) {
    return cancelAuthEmail({
      id,
    })
  }

  /**
   * 查询中心站开启配置
   * @returns  {Promise}
   */
  findCentralConfigApi() {
    return findCentralConfig()
  }

  /**
   * 中心站开启配置
   *
   * @param {boolean} enabled - central config的启用状态。
   * @param {string} scope - central config的范围。
   * @param {string} id - central config的ID。
   * @return {any} centralConfig函数的返回结果。
   */
  centralConfigApi({ enabled, scope, id }) {
    return centralConfig({
      enabled,
      scope,
      id,
    })
  }

  tenantEmailsApi() {
    return tenantEmails()
  }

  /**
   * 配置邮箱是否开启中心站
   *
   * @param {boolean} centerEnabled - 是否开启中心站（1：是，0否）
   * @param {string} mailAccountIds - 账户ID
   * @return {any} centralConfig函数的返回结果。
   */
  centerEnableApi({ id, enabled, scope, openAccountIds, closeAccountIds }) {
    return centerEnable({ id, enabled, scope, openAccountIds, closeAccountIds })
  }

  mailCheckerListApi(data) {
    return mailCheckerList(data)
  }

  getMailAccountListByUserIdApi(data) {
    return getMailAccountListByUserId(data)
  }

  getMemberMailListApi(data) {
    return getMemberMailList(data)
  }

  getMailDetailByIdApi(data) {
    return getMailDetailById(data)
  }

  getEmailNumber({
    emailAccount,
    flag,
    boxName,
    labelId,
    boxFullName,
    mailAccountUserIds,
  }) {
    return getEmailNumber({
      emailAccount,
      flag,
      boxName,
      labelId,
      boxFullName,
      mailAccountUserIds,
    })
  }

  getEmailAuthNumber({
    emailAccount,
    flag,
    boxName,
    businessId,
    labelId,
    boxFullName,
    mailAccountUserIds,
  }) {
    return getEmailAuthNumber({
      emailAccount,
      flag,
      boxName,
      businessId,
      labelId,
      boxFullName,
      mailAccountUserIds,
    })
  }

  processed({ mailServer, mailIdList, mailAccountUserIds }) {
    return processed({ mailServer, mailIdList, mailAccountUserIds })
  }
  centralUserListApi(data) {
    const { name, status } = data
    return centralUserList({ name, status })
  }
  centralEmailListApi(data) {
    return centralEmailList(data)
  }

  async mailSettingDetailApi(tenantId, key) {
    let res = await mailSettingDetail({
      tenantId,
    })
    if (res?.code === '000000') {
      return key ? res.data[key] : res.data
    }
  }
}

mailSetting._instance = new mailSetting()

export const MailSettingInteractor = mailSetting.getInstance()
