/* eslint-disable prettier/prettier */
import {
  getDetail,
  price,
  delivery,
} from '@/core/services/api/product/productPricing'

class productPricingInteractor {
  static getInstance() {
    return this._instance
  }
  /**
   * 查询产品详情
   * @param {productId} 产品id
   * @returns
   */
  async getDetailApi(data) {
    let { productId } = data
    return await getDetail({ productId })
  }

  /**
   * 计算产品报价
   * @param {printingLocationId} 印刷位置ID
   * @param {printingMethodId} 刷方式ID
   * @param {printingPriceId} 印刷计价方式ID
   * @param {productId} 产品id
   * @param {quantities} 产品数量
   * @param {quantityDTOList} 产品规格数量阶梯
   * @param {reOrder} 是否开启返单版费 0-否 1-是
   * @returns
   */
  async priceApi(data) {
    let {
      printingLocationId,
      printingMethodId,
      printingPriceId,
      productId,
      quantities,
      quantityDTOList,
      reOrder,
      auditProcess,
      mallStatus,
      estimateType,
      postcode,
      street,
      residentialType,
      country,
    } = data
    return await price({
      printingLocationId,
      printingMethodId,
      printingPriceId,
      productId,
      quantities,
      quantityDTOList,
      reOrder,
      auditProcess,
      mallStatus,
      estimateType,
      postcode,
      street,
      residentialType,
      country,
    })
  }

  /**
   * 计算预计交付日期
   * @param {printingLocationId} 印刷位置ID
   * @param {printingMethodId} 刷方式ID
   * @param {printingPriceId} 印刷计价方式ID
   * @param {productId} 产品id
   * @param {quantities} 产品数量
   * @param {quantityDTOList} 产品规格数量阶梯
   * @param {reOrder} 是否开启返单版费 0-否 1-是
   * @returns
   */
  async deliveryApi(data) {
    let {
      printingLocationId,
      printingMethodId,
      printingPriceId,
      productId,
      quantities,
      auditProcess,
      mallStatus,
    } = data
    return await delivery({
      printingLocationId,
      printingMethodId,
      printingPriceId,
      productId,
      quantities,
      auditProcess,
      mallStatus,
    })
  }
}

productPricingInteractor._instance = new productPricingInteractor()

export const ProductPricingInteractor = productPricingInteractor.getInstance()
