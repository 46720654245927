import Vue from 'vue'
import { hasAccess } from '@/utils/hasAccess'
import { specialChars } from 'kits'

Vue.use(specialChars) // 禁止输入框输入特殊字符，特殊字符可以自定义

/**
 * @description 自定义指令v-permissions
 */
Vue.directive('permissions', {
  inserted(el, binding) {
    const { value } = binding
    if (value) {
      if (!hasAccess(value)) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    }
  },
})

/**
 * @description 自定义指令v-drag
 */
Vue.directive('drag', {
  bind(el, binding, vNode) {
    if (
      el.querySelector('.el-dialog__header') &&
      el.querySelector('.el-dialog')
    ) {
      const dialogHeaderEl = el.querySelector('.el-dialog__header')
      const dragDom = el.querySelector('.el-dialog')
      dialogHeaderEl.style.cssText += ';cursor:move;'
      dragDom.style.cssText += ';top:0;'

      const getStyle = (function () {
        if (window.document.currentStyle) {
          return (dom, attr) => dom.currentStyle[attr]
        } else {
          return (dom, attr) => getComputedStyle(dom, null)[attr]
        }
      })()

      dialogHeaderEl.onmousedown = (e) => {
        const disX = e.clientX - dialogHeaderEl.offsetLeft
        const disY = e.clientY - dialogHeaderEl.offsetTop

        const dragDomWidth = dragDom.offsetWidth
        const dragDomHeight = dragDom.offsetHeight

        const screenWidth = document.body.clientWidth
        const screenHeight = document.body.clientHeight

        const minDragDomLeft = dragDom.offsetLeft
        const maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth

        const minDragDomTop = dragDom.offsetTop
        const maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomHeight

        let styL = getStyle(dragDom, 'left')
        let styT = getStyle(dragDom, 'top')

        if (styL.includes('%')) {
          styL = +document.body.clientWidth * (+styL / 100)
          styT = +document.body.clientHeight * (+styT / 100)
        } else {
          styL = +styL.slice(0, -2)
          styT = +styT.slice(0, -2)
        }

        document.onmousemove = function (e) {
          let left = e.clientX - disX
          let top = e.clientY - disY

          if (-left > minDragDomLeft) {
            left = -minDragDomLeft
          } else if (left > maxDragDomLeft) {
            left = maxDragDomLeft
          }

          if (-top > minDragDomTop) {
            top = -minDragDomTop
          } else if (top > maxDragDomTop) {
            top = maxDragDomTop
          }

          dragDom.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`

          vNode.child.$emit('drag-dialog')
        }

        document.onmouseup = function () {
          document.onmousemove = null
          document.onmouseup = null
        }
      }
    }
  },
})
/**
 * @description 限制输入框只能输入英文字母数字 并且首字母大写
 */
Vue.directive('inptRules', {
  inserted: function (e) {
    const el = e.querySelector('input')
    el.addEventListener('input', function (e) {
      //进行验证
      checkedfun(el)
    })
    function checkedfun(el) {
      // 为空退出
      if (!el.value) return
      let reg = new RegExp('^[\u4E00-\u9FA5]*$')
      if (!reg.test(el.value)) {
        el.value = el.value
          .replace(/[^\sA-Za-z0-9$]+/g, '')
          .replace(/\b\w+\b/g, (word) => {
            return word.substring(0, 1).toUpperCase() + word.substring(1)
          })
        //调用input事件使vue v-model绑定更新
        el.dispatchEvent(new Event('input'))
      }
    }
  },
})

Vue.directive('inptTextareaRules', {
  inserted: function (e) {
    const el = e.querySelector('textarea')
    el.addEventListener('textarea', function (e) {
      //进行验证
      checkedfun(el)
    })
    function checkedfun(el) {
      // 为空退出
      if (!el.value) return
      let reg = new RegExp('^[A-Z0-9][A-z0-9]*$')
      if (!reg.test(el.value)) {
        el.value = el.value
          .replace(/[^\sA-Za-z0-9]+/g, '')
          .replace(/\b\w+\b/g, (word) => {
            return word.substring(0, 1).toUpperCase() + word.substring(1)
          })
        //调用input事件使vue v-model绑定更新
        el.dispatchEvent(new Event('textarea'))
      }
    }
  },
})
Vue.directive('inptRulesNum', {
  inserted: function (e) {
    const el = e.querySelector('input')
    el.addEventListener('input', function (e) {
      //进行验证
      checkedfun(el)
    })
    function checkedfun(el) {
      // 为空退出
      if (!el.value) return
      let reg = new RegExp('/^[1-9]d*/')
      if (!reg.test(el.value)) {
        el.value = el.value.replace('.')

        //调用input事件使vue v-model绑定更新
        el.dispatchEvent(new Event('input'))
      }
    }
  },
})

/**
 * @description 输入框获取焦点
 */
Vue.directive('focus', {
  inserted: function (el) {
    el.querySelector('input').focus()
  },
})

Vue.directive('loadmore', {
  bind(el, binding) {
    let selector = binding.arg
      ? '.' + binding.arg
      : '.el-autocomplete-suggestion__wrap.el-scrollbar__wrap'
    // 如果指令使用时传了修饰符，需要特殊处理下。
    // bug场景：开户行通过插槽添加了名称和联行号下拉，此时querySelector会获取到第一个el-select-dropdown__list，为解决此问题，将目标类名的父级类名通过修饰符传递过来
    const modifierList = Object.keys(binding.modifiers)
    if (modifierList.length && modifierList[0] === 'bank-select') {
      selector = `.${modifierList[0]} ${selector}`
    }
    // 获取element-ui定义好的scroll盒子
    const SELECTWRAP_DOM = el.querySelector(selector)
    SELECTWRAP_DOM.addEventListener('scroll', function () {
      const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight
      if (CONDITION) {
        binding.value()
      }
    })
  },
})

/**
 * 使用 v-only-number.positive.fill="3"
 *
 *    ="3" 表示允许输入小数，小数位数最多3位，默认为0
 *    positive 表示只能输入0和正数
 *    fill 表示自动补零。前提是允许输入小数，否则不要写fill修饰符
 *
 */

Vue.directive('only-number', {
  bind: function (el, { value = 0, modifiers }) {
    el = el.nodeName == 'INPUT' ? el : el.children[0]
    const RegStr =
      value == 0
        ? `^[\\+\\-]?\\d+\\d{0,0}`
        : `^[\\+\\-]?\\d+\\.?\\d{0,${value}}`
    el.addEventListener('keyup', function () {
      if (el.value != '-') {
        el.value = el.value.match(new RegExp(RegStr, 'g'))
        if (modifiers.positive) {
          el.value = el.value.replace('-', '')
        }
        el.dispatchEvent(new Event('input'))
      }
    })
    el.addEventListener('blur', function () {
      let num = el.value.match(new RegExp(RegStr, 'g')) || '0.00'
      // 自动补零
      if (modifiers.fill) {
        let str = num.toString()
        let decimalPosition = str.indexOf('.')
        if (decimalPosition < 0) {
          decimalPosition = str.length
          str += '.'
        }
        while (str.length <= decimalPosition + Number(value)) {
          str += '0'
        }
        num = str
        el.value = num
      }
      if (modifiers.positive) {
        el.value = el.value.replace('-', '')
      }
      el.dispatchEvent(new Event('input'))
    })
  },
})

// 点击当前区域之外的位置
Vue.directive('clickoutside', {
  // 初始化指令
  bind(el, binding) {
    const documentHandler = (e) => {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) return
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e)
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.__vueClickOutside__ = documentHandler
    document.addEventListener('click', documentHandler)
  },
  unbind(el) {
    // 解除事件监听
    document.removeEventListener('click', el.__vueClickOutside__)
    delete el.__vueClickOutside__
  },
})
